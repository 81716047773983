<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Estoque</h3>
        </div>
        <div class="card-body">
          <b-table
              :fields="['produto','codigo_barra','fracao','descricao','estoque', 'acoes']"
              :items="lista_produtos_estoque"
              :per-page="perPage"
              :current-page="currentPage"
              id="estoque-table"
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
          >

              <template #head(acoes)><span></span></template>
              <template #cell(acoes)="{item}">
              <div class="text-right w-100">
                     
        
      
                <button
                 v-show="lista_permissoes_filial.u_Sala"
                    @click="atualizar(item)"
                     class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Editar registro"
                >
                 <i class="fa fa-minus text-warning "></i>
                </button>

                <button
                 v-show="lista_permissoes_filial.lock_Sala"
                    @click="confirm(item)"
                 class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
                         </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_produtos_estoque.length"
              :per-page="perPage"
              aria-controls="estoque-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins:{fireAlert},
  data() {
    return {
         currentPage: 1,
      perPage: 10
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Estoque"}]);
  },
  created() {
    this.listar_produto_estoque();
  },
  computed: {
    
    lista_produtos_estoque() {
      return this.$store.state.produto.lista_produtos_estoque;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
  },
  methods: {

    async listar_produto_estoque() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("produto/listar_produto_estoque").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("produto/listar_produto", "");
          // localStorage.removeItem('produto');
            
      });
    },
    atualizar(value) {
    //   this.$router.push({name: "produtoDados",params:{produto_id : value.id}});
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
        // this.$store.dispatch("produto/listar_produto", value.id);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara esta Sala no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("produto/bloquear_estoque_produto", value);
         this.fireAlert({
        ...this.mensagem_alert, routeName: 'atendimento'
      });
    },
 
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>